.controls {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  gap: var(--fxl-spacing-xxs);
  &.size-large {
    // flex-wrap: wrap;
    flex-direction: row;
    gap: var(--fxl-spacing-xs);
  }
}
.blade {
  display: flex;
  gap: var(--fxl-spacing-xxs);
  align-items: center;
  background: var(--fxl-color-background1);
  padding: 0 0 0 var(--fxl-spacing-xxs);
  &:hover {
    background: var(--fxl-color-background2);
  }
  & > div {
    min-width: 0;
    flex-grow: 1;
  }
  &.size-large {
    border-radius: var(--fxl-spacing-xs);
    flex-basis: 50%;
    padding: var(--fxl-spacing);
    margin-bottom: var(--fxl-spacing-xs);
  }
  .lockButton {
  }
}
