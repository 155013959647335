.root {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: min(2vh, 2.5vw);
  height: 100%;
}

.debug {
  top: 0;
  left: 0;
  z-index: 11;
  position: absolute;
  max-width: 300px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 14px;
  overflow: hidden;
  word-break: break-all;

  p {
    margin: 0 0 4px;
  }
}

.details {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
  /*display: none;*/
}

.details h3 {
  margin-bottom: 0.8em;
}

.details p {
  margin-top: 0.5em;
}

.qr_container {
  display: flex;
  flex-direction: row;
  text-align: right;
  gap: 1em;
  font-size: 0.8em;
  line-height: 1.4;
}

.qrcode {
  width: 10vh !important;
  height: 10vh !important;
}

.frame_wrapper {
  width: 100%;
  flex-grow: 2;
  position: relative;
}

.artwork_mask {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.reveal_iframe_container {
  height: min(90vw, 80vh) !important;
  width: min(90vw, 80vh) !important;
  margin-left: auto;
  margin-right: auto;
}

.reveal_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.reveal {
  margin: 1.5em 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.scroned_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.scroned {
  width: 32px;
  height: auto;
}

.infos__user_badge {
  font-size: 1rem;
  font-weight: normal !important;
  color: white !important;
}
