.button {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  padding: 1em 2em;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 3px;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
