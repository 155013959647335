.root {
  background-color: var(--color-black);
  color: var(--color-white);
  border: 2px solid var(--color-white);
  border-radius: 200px;
  height: 2.2em;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    letter-spacing: 0.05em;
    display: block;
    line-height: 1;
    top: 0.45em;
  }
}
