.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .image {
    z-index: 0;
  }

  .frame {
    z-index: 1;
    opacity: 0;

    &.loaded {
      opacity: 1;
    }
  }
}
