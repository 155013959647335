.root {
  width: 600px;
  padding-top: 20vh;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  height: 100vh;
}

.mode_settings {
  margin-top: 30px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transition-property: opacity, transform;
  transform: translateY(80px);

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
}

.submit {
  margin-top: 2.5em;
  display: flex;
  justify-content: center;
}

.fastLinks {
  position: fixed;
  top: 10px;
  right: 10px;
  opacity: 0;

  &:focus {
    outline: 8px solid red;
    opacity: 1;
  }
}
