.rightColumn {
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  color: var(--color-gray);
  h1 {
    margin-bottom: 0.5em;
  }
  h2 {
    font-weight: normal;
  }
  .description {
    margin-top: 2em;
    line-height: 1.3;
    flex-grow: 1;
    font-family: "Inter", sans-serif;
    letter-spacing: -0.05em;
    font-size: 0.8em;

    &.error {
      color: var(--color-error);
    }
  }

  .qr {
    width: 100%;
    display: flex;
    justify-content: center;
    canvas {
      width: 50%;
    }
    margin-bottom: 1em;
  }
}

.qrWrapper {
  position: absolute;
  bottom: 0;
  background-color: inherit;
  padding-top: 1.5em;
}
