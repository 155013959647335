.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 100%;

  &.vertical_space {
    justify-content: space-between;
    height: 100%;
    padding-bottom: calc(2 * var(--gutter));
  }
}
.iteration_details {
  margin-top: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.iteration {
  font-size: 1em;
  line-height: 0.63;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.owner {
  margin-top: 0.5em;
  font-size: 0.8em;
  line-height: 0.63;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-gray);
  gap: 0.5em;

  a,
  * {
    color: var(--color-gray) !important;
    font-weight: normal;
  }

  .badge_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.features_wrapper {
  margin-top: 3em;
  position: relative;
  overflow-y: auto;

  &.grey_border {
    --color-black: #4b4949;
  }
}

.frame_wrapper {
  width: 100%;
  position: relative;
  padding-top: 100%;
}
.image_wrapper {
  position: relative;
  padding-bottom: 100%;
}
.img_center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.rarity_badge {
  font-size: 1.1em;
}

.rarity_distrib {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    color: var(--color-gray);
  }

  .distrib_entry {
    flex-shrink: 0;
    font-size: 1.1em;
    line-height: 0.63;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--color-black);
    padding: 0.7em 0.7em;
  }
}
