.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.logo {
  position: fixed;
  bottom: 8px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: var(--font-size-sm);

  img {
    width: 24px;
  }
}
