.container {
  display: flex;
  flex-direction: column;
}

.progress_bar {
  background-color: var(--color-gray);
  transition: width 1s linear;
  height: 10px;
  margin-bottom: 8px;
}

.label {
  align-self: center;
  color: var(--color-gray);
  font-size: 0.5em;
}
