.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-size: min(1.5vw, 1.5vh);

  .main {
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h1,
  svg {
    color: red;
  }

  svg {
    margin-bottom: 10px;
  }

  .message {
    margin-top: 2em;
    color: var(--color-gray-dark);
  }
}
