.container_cols {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--gutter);
}

.container {
  flex: 1 1 0;
}

.col {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: calc(var(--gutter));

  &.col_top {
    flex-direction: column;
  }

  img {
    width: 100%;
  }
}

.iteration {
  position: relative;
}
