.container {
  display: block;
  // padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.no_pad {
    padding-left: 0px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  height: 26px;
  width: 26px;
  display: block;
  background-color: transparent;
  border: 2px solid var(--color-gray-light);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.radio .checkmark {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  border-color: var(--color-gray);

  &:after,
  &:before {
    background-color: var(--color-gray);
  }
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark {
  &:after,
  &:before {
    display: block;
  }
}

.container input:focus ~ .checkmark {
  outline: 6px solid var(--color-focus);
}

/* Style the checkmark/indicator */
.container .checkmark {
  &:after,
  &:before {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 32px;
    height: 3px;
    background-color: var(--color-gray-light);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
