.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .frame {
    width: 100vh;
    height: 100vh;
    background-color: black;
    flex-shrink: 0;
    position: relative;
  }

  .panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2.6em 2em;
    overflow-wrap: break-word;
    word-break: break-word;
    background-color: #161616;
    flex-grow: 2;

    h1 {
      font-size: 2.5em;
    }

    h2 {
      margin-top: 0.5em;
      font-weight: normal;

      .by {
        opacity: 0.4;
      }
    }

    .descWrapper {
      flex-grow: 2;
      position: relative;
      margin-top: 2.5em;

      .desc {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        color: #c6c6c6;
        font-family: "Inter", sans-serif;
        font-size: 1.4em;
        line-height: 1.4;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 1em;

      button {
        border-radius: 4px;
        background-color: black;
        border: none;
        font-family: inherit;
        padding: 1.3em;
        font-size: 1.4em;
        display: flex;
        flex-direction: row;
        padding-bottom: 1.1em;
        font-weight: bold;
        justify-content: space-between;
        cursor: pointer;

        &.mint {
          background-color: var(--color-secondary);
        }
      }
    }
  }
}

.blur {
  filter: blur(40px);
  pointer-events: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-color: var(--fxl-color-background1);
  padding: var(--fxl-spacing-xxl);
  max-width: 600px;
  border-radius: var(--fxl-spacing-sm);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  .qr {
    display: flex;
    justify-content: center;
    canvas {
      width: 60%;
    }
  }
  .qr_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px;
  }
  p {
    margin-top: var(--fxl-spacing-l);
    margin-bottom: var(--fxl-spacing-l);
    line-height: 1.5;
  }
  button {
    width: 100%;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
