$height: 30px;

.controller {
  display: flex;
  align-items: center;
  gap: var(--fxl-spacing-xxs);
  color: var(--fxl-color-text-primary);
  &:hover {
    color: var(--fxl-color-text-primary-hover);
    input[type="range"] {
      background: var(--fxl-color-light1);
    }
  }
  .labelContainer {
    label + p {
      margin-top: var(--fxl-spacing-xxs);
    }
    p {
      color: var(--fxl-color-text-secondary);
      font-size: var(--fxl-font-size-s);
    }
  }
  label {
    color: currentColor;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    font-size: var(--fxl-font-size-s);
    &.ellipsis {
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  input {
    color: currentColor;
  }
  .inputContainer {
    width: 200px;
    display: flex;
    align-items: center;
  }
  &.size-large {
    flex-direction: column;
    align-items: flex-start;
    .labelContainer {
      margin-bottom: var(--fxl-spacing-xs);
    }
    label {
      font-size: var(--fxl-font-size-m);
      font-weight: bold;
    }
    .inputContainer {
      width: 100%;
    }
  }
}

.controllerWithText {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;
  align-items: center;

  .controllerText {
    font-size: 2em;
    font-weight: bold;
  }
}

.default {
  input,
  select {
    &:nth-child(1) {
      margin-right: var(--fxl-spacing-xxs);
      width: 65%;
    }
    &:nth-child(2) {
      margin-left: var(--fxl-spacing-xxs);
      width: 35%;
    }
    &:only-child {
      width: 100%;
      margin-right: 0;
    }
  }
}

.invert {
  input,
  select {
    &:nth-child(1) {
      margin-right: var(--fxl-spacing-xxs);
      width: 30%;
    }
    &:nth-child(2) {
      margin-left: var(--fxl-spacing-xxs);
      width: 70%;
    }
    &:only-child {
      width: 100%;
      margin-right: 0;
    }
  }
}

.box {
  input,
  select {
    &:nth-child(1) {
      margin-right: var(--fxl-spacing-xxs);
      width: $height;
    }
    &:nth-child(2) {
      margin-left: var(--fxl-spacing-xxs);
      width: calc(100% - $height);
    }
    &:only-child {
      width: $height;
      margin-right: 0;
    }
  }
}

.checkbox {
  &.size-large {
    flex-direction: row;
    align-items: center;
    .labelContainer {
      margin-bottom: 0;
      flex-grow: 1;
    }
    .inputContainer {
      width: auto;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberInput[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 450px) {
  .controller .inputContainer {
    width: 125px;
  }
}
