$size-normal: 30px;
$size-large: 50px;

.baseInput,
.baseSelect,
.baseButton {
  font-family: var(--fxl-font-family);
  font-size: var(--fxl-font-size-regular);
  color: var(--fxl-color-text-primary);
  background: #212121;
  border: 1px solid var(--fxl-color-light3);
  padding: 0 var(--fxl-spacing-xxs);
  outline-offset: -1px;

  &.size-normal {
    height: $size-normal;
  }

  &.size-large {
    height: $size-large;
    background: #212121;
    border-radius: var(--fxl-spacing-xxs);
    padding: 0 var(--fxl-spacing-xs);
    &:hover {
      background: var(--fxl-color-light2);
    }
  }

  &:hover {
    background: var(--fxl-color-background3);
    color: var(--fxl-color-text-primary-hover);
  }

  &:focus-visible {
    outline: 1px solid var(--color-secondary);
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.baseButton {
  cursor: pointer;
  background: var(--fxl-color-background2);
  color: var(--fxl-color-text-primary);
  font-size: 1.2em;

  &:hover {
    background: var(--fxl-color-background3);
    color: var(--fxl-color-light);
    outline: 1px solid var(--color-secondary);
  }
  &:focus-visible {
    outline: 1px solid var(--color-secondary);
    outline-offset: 2px;
  }
  &.color-secondary {
    background: var(--fxl-color-dark1);
    color: var(--fxl-color-light);
    border-color: var(--fxl-color-dark2);

    &:hover {
      background: var(--fxl-color-dark2);
      border-color: var(--fxl-color-dark3);
      color: var(--fxl-color-light);
      outline: 1px solid var(--color-secondary);
    }
  }

  &.color-main {
    transition: 0.2s all ease-out;
    background: var(--color-secondary);
    color: white;

    &:hover {
      background: var(--color-secondary-vlight);
      color: var(--fxl-color-light);
      outline: 1px solid var(--color-secondary);
      outline-offset: 2px;
    }
    &:active {
      background: var(--color-secondary-dark);
    }
  }

  &.color-primary-inverted {
    transition: 0.2s all ease-out;
    background: var(--color-secondary);
    color: var(--fxl-color-light);

    &:hover {
      background: var(--color-secondary);
      color: var(--fxl-color-light);
      outline: 1px solid var(--color-secondary);
      outline-offset: 2px;
    }
    &:active {
      background: var(--color-secondary-dark);
    }
  }
}

.iconButton {
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  &.size-normal {
    height: $size-normal !important;
    width: $size-normal !important;
  }
  &.size-large {
    height: $size-large !important;
    width: $size-large !important;
  }
}

$slider-track-height-normal: 3px;
$slider-thumb-size-normal: $size-normal / 2;
$slider-track-height-large: 3px;
$slider-thumb-size-large: $size-large / 2;

$slider-thumb-border-radius: 50%;

$checkbox-thumb-spacing-normal: $slider-thumb-size-normal;
$checkbox-thumb-spacing-large: $slider-thumb-size-large;

.baseInput[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  &.size-normal {
    height: $size-normal !important;
    width: $size-normal !important;
    &:checked {
      &::after {
        width: $checkbox-thumb-spacing-normal;
        height: $checkbox-thumb-spacing-normal;
      }
    }
  }
  &.size-large {
    height: $size-large !important;
    width: $size-large !important;
    &:checked {
      &::after {
        box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 1);
        border-radius: var(--fxl-spacing-xxs);
        width: $checkbox-thumb-spacing-large;
        height: $checkbox-thumb-spacing-large;
      }
    }
  }
  &:checked {
    outline: 1px solid white;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
    }
  }
}
