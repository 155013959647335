.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 6.29vh 0;
}

.feature_category {
}

.feature_category_title {
  font-size: 1.3em;
  line-height: 0.63;
  letter-spacing: 0.02em;
  margin-bottom: 1.7em;
}

.multi_list {
}

.no_filters {
  color: var(--color-gray);
}

.clear_filters_wrapper {
  padding-bottom: var(--gutter);

  button {
    width: 100%;
    padding: 0.5em 1.4em;
    padding-right: 0.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    cursor: pointer;
    background-color: white;
    color: var(--color-background);
    font-weight: bold;
    border-radius: 400px;

    &:active {
      transform: translateY(3px);
      box-shadow:
        0 -3px 0 0 var(--color-background) inset,
        0 -3px 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .filters_count {
    background-color: var(--color-background);
    width: 3em;
    padding: 0.55em 0 0.45em 0;
    border-radius: 200px;
    color: white;
  }
}
