.select {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mode {
  border: 1px solid var(--color-gray-vvlight);
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 16px 8px 16px 0;
  border-radius: 3px;
  position: relative;

  .close {
    display: none;
    position: absolute;
    color: var(--color-error);
    font-size: 40px;
    right: 15px;
  }

  &:focus {
    outline: none;
  }

  &.selected {
    border-color: var(--color-success);
    border-width: 3px;
  }

  &:hover {
    border-color: var(--color-gray-vlight);
    background-color: var(--color-gray-vvlight);

    &.selected {
      border-color: var(--color-error);

      .close {
        display: block;
      }
    }
  }

  &:focus-visible {
    outline: 6px solid var(--color-focus);
  }

  &.selected:focus-visible {
    border-color: var(--color-error);

    .close {
      display: block;
    }
  }

  .icon_wrapper {
    width: 60px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .description {
    margin-top: 3px;
  }
}
