.root {
  height: 100%;
}

.carousel_wrapper {
  height: var(--carousel-height);
  position: relative;
  margin-top: calc(3 * var(--gutter));
}

.carousel {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--gutter);
  position: absolute;
  left: 50%;
  transition: transform 2s ease-in-out;

  .item {
    width: var(--carousel-height);
    height: var(--carousel-height);
    background-color: yellow;
    opacity: 0.3;
    transition: opacity 2s ease-in-out;
    position: relative;

    &.active {
      opacity: 1;
    }
  }
}
