.root {
  height: 100vh;
  width: 100vw;
  font-size: min(2vw, 2vh);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  gap: 2em;

  img {
    width: 10vw;
  }

  .title {
  }

  .fx {
    font-size: min(100vw, 100vh);
    position: fixed;
    font-weight: bold;
    filter: blur(4vw);
    opacity: 0.2;
    pointer-events: none;
  }
}
