.container {
  perspective: 1000px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe_container {
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
  background: black;
  position: relative;
  z-index: 10;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: black;
  }

  &.loaded {
    .loader {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.animation-rotate {
  transform: rotateY(180deg);
  &.loaded {
    transform: rotateY(0deg);
    transition: transform 3s ease-in-out;
    iframe {
      opacity: 1;
      transition-property: opacity;
      transition-duration: 0s;
      transition-delay: 1.5s;
    }
  }
}

.animation-fade {
  background-color: transparent;
  iframe {
    opacity: 0;
    background-color: transparent;
  }
  &.loaded {
    opacity: 1;
    iframe {
      transition: opacity 3s ease-in-out;
      opacity: 1;
      transition-property: opacity;
      // transition-duration: 0s;
      transition-delay: 1s;
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.2s ease-out;
  transform: rotateY(180deg);
}
