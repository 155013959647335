.container {
  display: flex;
  align-items: center;

  .user_infos {
    margin-left: 0.5em;
  }

  .user_name {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 0.25em;
    text-align: left;
  }

  .user_address {
    color: var(--color-gray);
    font-weight: normal;
    font-size: 0.8em;
  }

  .verified {
    font-size: 1.15em;
    bottom: -1px;
    position: relative;
  }

  &.side-left {
    flex-direction: row;
  }

  &.side-right {
    flex-direction: row-reverse;

    .avatar {
      margin-right: 0;
      margin-left: 0.5em;
    }

    .user_name {
    }
    .user_infos {
      margin-left: 0;
    }
  }
}

.no_avatar {
  .user_infos {
    margin-left: 0;
  }
}

.default_font_styles {
  color: var(--color-primary);
  font-weight: bold;
}

.link {
  display: inline-block;
}

.link:hover {
  .avatar {
    transform: scale(1.05);
  }

  .append {
    text-decoration: none !important;
  }
}

.avatar {
  transition: all 0.3s ease-out;
  flex-shrink: 0;
}

.avatar-small {
  width: 1.2em;
  height: 1.2em;
}

.avatar-regular {
  width: 37px;
  height: 37px;
}

.avatar-big {
  width: 50px;
  height: 50px;
}

.prepend {
  color: var(--color-black);
  margin-right: 3px;
}

.avatar_mod {
  border: 3px solid var(--color-success);
}

.moderator {
  color: var(--color-success);
}

.donation {
  color: var(--color-donator);
  font-weight: bold;
}

.avatar_donation {
  border: 3px solid var(--color-donator);
}
