.tezos {
  margin-right: 6px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;

  &.tezos_small {
    font-size: 0.7em;
  }

  &.tezos_regular {
    font-size: 1em;
    position: relative;
    top: 2px;
  }

  &.tezos_big {
    font-size: 1.3em;
  }
}
