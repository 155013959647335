.features {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: calc(2 * var(--gutter));
}

.feature,
.big_feature {
  flex-shrink: 0;
  font-size: 1.1em;
  line-height: 0.63;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--color-black);
  padding: 0.7em 0.7em;

  small {
    font-size: 0.65em;
    opacity: 0.5;
  }

  .value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.1em;
  }
}

.big_feature {
  height: 4.25em;
  color: var(--color-white);
  background-color: var(--color-black);
  position: sticky;
  top: 0;

  .rarity {
    height: 2.25em;
    border: 3px solid currentColor;
    font-weight: bold;
    padding: 0 1em;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
  }
}
