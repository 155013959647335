.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.gentks {
  display: flex;
  flex-direction: column;
  gap: 1.85vh 0;
}

.gentk {
  display: grid;
  grid-template-columns: 5.92vh auto;
  grid-auto-rows: 5.92vh;
  border: 2px solid var(--color-black);
  opacity: 0.4;
  transition: 0.2s all ease-out;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &_title {
    padding: 0 0.7em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    line-height: 0.63;
    text-align: center;
  }

  &--selected {
    opacity: 1;
    background-color: var(--color-black);
    color: var(--color-background);
  }
}

.filters {
  padding: 1.2vh 0 calc(2 * var(--gutter)) 0;
  flex: 1;
  overflow-y: auto;
}
