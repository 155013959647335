.progress {
  background: var(--color-gray-light);
  width: 100%;
  height: 6px;
  position: relative;
  margin-bottom: 0.5em;
}
.bar {
  position: absolute;
  top: 0;
  height: 100%;
  transition: width 0.15s ease-out;
  background-color: var(--color-gray-dark);
  left: 0;
}

.label {
  color: var(--color-gray);
}
