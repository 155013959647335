@import "./Variables.scss";

/** COLORS USED THROUGH THE APP */
:root {
  --color-gray-vvlight: #141414;
  --color-gray-vlight: #242424;
  --color-gray-light: #565656;
  --color-gray: #878787;

  --color-border-input: #7e7e7e;
  --color-secondary: #7000ff;
  --color-white: #242424;
  --color-black: white;
  --color-gray-vvvlight: #2b2b2b;
  --color-gray-dark: #cccccc;
  --color-secondary-light: #7b9811;
  --color-border: #0f0f0f;
  --color-focus: #cc00ff;

  --color-primary: #ff005c;
  --color-success: #00eda0;
  --color-error: #eb1b1b;
  --color-error-20: rgba(255, 0, 0, 0.2);
  --color-warning: #ffc300;

  --font-size-sm: 0.8rem;

  --font-size-vsmall: 0.7rem;
  --font-size-small: 0.875rem;
  --font-size-regular: 1rem;
  --font-size-big: 1.125rem;
  --font-size-h6: 1rem;
  --font-size-h5: 1.25rem;
  --font-size-h4: 1.5625rem;
  --font-size-h3: 2rem;
  --font-size-h2: 2.5rem;
  --font-size-h1: 3rem;

  --font-size-paragraph: 1.3125rem;

  --font-family-primary: "Fira Code", monospace;

  --fxl-color-light: #ffffff;
  --fxl-color-light0: #bbbbbb;
  --fxl-color-light1: #727272;
  --fxl-color-light2: #424242;
  --fxl-color-light3: #343434;
  --fxl-color-dark: #000000;
  --fxl-color-dark0: #111111;
  --fxl-color-dark1: #181818;
  --fxl-color-dark2: #1c1c1c;
  --fxl-color-background1: #363636;
  --fxl-color-background2: #2e2e2e;
  --fxl-color-background3: #2b2b2b;

  --fxl-color-background1: var(--fxl-color-dark0);
  --fxl-color-background2: var(--fxl-color-dark1);
  --fxl-color-background3: var(--fxl-color-dark2);

  --fxl-font-family: var(--font-family-primary);
  --fxl-color-primary: var(--color-primary);
  --fxl-color-primary-light: var(--color-primary);
  --fxl-color-secondary: var(--color-secondary);
  --fxl-color-secondary-light: var(--color-secondary-light);
  --fxl-color-background: var(--color-white);
  --fxl-color-background-hover: var(--color-white);
  --fxl-color-text-primary: var(--fxl-color-light);
  --fxl-color-text-primary-hover: var(--fxl-color-light);
  --fxl-color-text-secondary: var(--fxl-color-light1);

  --fxl-color-panel-background: var(--fxl-color-dark);

  --fxl-font-size-xs: 0.7em;
  --fxl-font-size-s: 1em;
  --fxl-font-size-regular: 1.5em;
  --fxl-font-size-m: 1.8em;
  --fxl-font-size-l: 2.2em;

  --fxl-spacing: #{$spacing};
  --fxl-spacing-sm: #{$spacing-small};
  --fxl-spacing-xs: #{$spacing-x-small};
  --fxl-spacing-xxs: #{$spacing-2x-small};
  --fxl-spacing-xxxs: #{$spacing-3x-small};
  --fxl-spacing-l: #{$spacing-large};
  --fxl-spacing-xl: #{$spacing-x-large};
  --fxl-spacing-xxl: #{$spacing-2x-large};
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
input,
textarea,
button {
  font: inherit;
  color: inherit;
}
* {
  box-sizing: border-box;
  line-height: 1.2em;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Fira code", monospace;
  background-color: black;
  color: white;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:focus-visible,
button:focus-visible {
  outline: 6px solid var(--color-focus);
  border-color: transparent !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-gray);

  &:hover {
    background: var(--color-gray-dark);
  }

  &:active {
    background: var(--color-black);
  }
}

::-webkit-scrollbar-track {
  background: transparent;
}
