.root {
  --gutter: 2vw;
  --color-background: #1d2227;
  --carousel-height: 45vh;

  background-color: var(--color-background);
  height: 100vh;
  font-size: max(1vw, 1.3vh);
  overflow: hidden;
}

.carousel_wrapper {
}
