.container {
  --gutter: 2vw;
  --color-background: #1d2227;

  background-color: var(--color-background);
  height: 100vh;
  display: flex;
  flex-direction: row;
  font-size: max(1vw, 1vh);
  gap: calc(1 * var(--gutter));
}

.leftColumn {
  flex: 1;
  padding-left: var(--gutter);

  &.fading {
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 15%;
      z-index: 50;
    }

    &::before {
      top: 0;
      background-image: linear-gradient(
        180deg,
        var(--color-background),
        transparent
      );
    }

    &::after {
      bottom: 0;
      background-image: linear-gradient(
        0deg,
        var(--color-background),
        transparent
      );
    }
  }
}

.rightColumn {
  width: 25vw;
  padding: calc(1 * var(--gutter)) var(--gutter) 0 0;
  height: 100%;
  letter-spacing: -0.1em;

  h1,
  h2 {
    color: white;
  }

  h1 {
    font-size: 1.5em;
  }
}
