$height-normal: 30px;
$height-large: 50px;

.root {
  padding-top: 60%;
  position: relative;
  width: 100%;

  .rootContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

.squaredButton {
  width: $height-normal;
  height: $height-normal;
  padding: 0;
  border: 1px solid var(--fxl-color-light2);
  position: relative;
  background: white !important;
  &.size-large {
    width: $height-large;
    height: $height-large;
  }
  &.active {
    border-color: var(--fxl-color-primary);
  }
  &:focus-visible {
    outline-offset: -1px;
  }
}

.square {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pickerWrapper {
  & > div {
    align-items: flex-end !important;
  }
}

.pickerAbsoluteWrapper {
  position: absolute;
  bottom: 0;
  transform: translate(0, calc(100% + var(--fxl-spacing-xxs)));
  z-index: 10;
}

.picker {
  border: 1px solid var(--fxl-color-light2);
  input {
    background: var(--fxl-color-light);
  }
}

.colorful {
  margin-right: 1em;

  &.size-large {
    width: 100%;
    height: 100%;

    & > * {
      width: 100%;
    }
  }
  :global {
    .react-colorful__saturation {
      border-radius: 0;
    }
    .react-colorful__last-control {
      border-radius: 0;
    }
  }
}

.largeLabels {
  margin-left: 1em;
  .rgb {
    display: flex;
    margin-top: var(--fxl-spacing-xs);
    .largeLabelValue {
      width: 50px;
      margin-right: var(--fxl-spacing-sm);
    }
  }
}

.largeLabelLabel {
  user-select: none;
  font-size: 0.8em !important;
  font-weight: normal !important;
  color: var(--fxl-color-text-secondary) !important;
  display: block !important;
  margin-bottom: var(--fxl-spacing-xxs) !important;
}
.largeLabelValue {
  pointer-events: none;
  appearance: none !important;
  background: none !important;
  border: none !important;
  font-size: 1.6em !important;
  padding: 0 !important;
  margin-left: 0 !important;
}
