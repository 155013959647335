@keyframes loading {
  from {
    opacity: 70%;
  }
  to {
    opacity: 100%;
  }
}

.wrapper {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  left: 0;
  animation: none;
  border-radius: inherit;

  &.wrapper_contain {
    height: 100%;
    background-size: contain;

    img {
      object-fit: contain;
    }
  }

  &.wrapper_cover {
    height: 100%;
    background-size: cover;
    position: absolute;

    img {
      object-fit: cover;
    }
  }

  &.wrapper_responsive {
    background-size: cover;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    top: 0;
    left: 0;
    pointer-events: none;
    // transition: backdrop-filter 0.5s;
  }

  &.loading {
    animation: loading 2s ease-in-out 0s alternate-reverse infinite;

    &::after {
      backdrop-filter: blur(8px);
    }

    img {
      opacity: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
