@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.progres_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;

  .bar {
    height: 3px;
    width: 0;
    background-color: #464646;
    animation-name: progress;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    // overwritten by javascript
    animation-duration: 30s;
    animation-direction: normal;
    animation-fill-mode: both;
  }
}
