.container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 4px 0;
}
.button {
  padding: 0;
  height: 48px;
  width: 48px;
}
