.root {
  width: 100%;
  position: relative;
  padding: 0.5em 0;

  .slider {
    background: #343434;
    height: 0.8vw;
    position: relative;
    border-radius: 5vw;
    pointer-events: none;

    .track {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-bottom-left-radius: 5vw;
      border-top-left-radius: 5vw;
      background-color: #9e9e9e;

      // will be updated
      width: 40%;
    }

    .thumb {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 2vw;
      height: 2vw;
      border-radius: 50%;
      background-color: #ffffff;

      // will be updated
      left: 40%;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0;
  }

  input:active ~ .slider {
    .thumb {
      transform: translate(-50%, -50%) scale(1.3);
    }
  }
}
