.controllerWrapper {
  display: flex;
  height: 100vh;
  filter: blur(0px);
  transition: filter 200ms ease-in-out;
}
.controllContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  padding: var(--fxl-spacing-sm);
  .controls {
    flex-grow: 1;
    overflow: auto;
  }
  .bottomControls {
    .buttonRow {
      width: 100%;
      display: flex;
      gap: var(--fxl-spacing-sm);
      margin-bottom: var(--fxl-spacing-sm);
      font-size: 1.2em;
      font-weight: bold;

      & > button {
        padding-top: 0.2em;
      }

      &:last-child {
        margin-bottom: 0;
      }
      & > * {
        flex-basis: 100%;
      }
      .refreshButton {
        flex-basis: 50%;
      }
    }
  }
}
.projectSidebar {
  width: 30vw;
  background-color: var(--fxl-color-background1);
  display: flex;
  flex-direction: column;
  font-size: 1.3em;
  letter-spacing: -0.05em;

  .projectInfo {
    padding: 0.52vw;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
  }
  .previewImage {
    margin-bottom: var(--fxl-spacing-sm);
  }
  h1 {
    font-size: var(--fxl-font-size-l);
    margin-bottom: var(--fxl-spacing-xs);
  }
  h2 {
    font-weight: normal;
    font-size: 1.7em;
    margin-bottom: var(--fxl-spacing-sm);
  }
  .artists {
    font-size: 1.2em;
  }
  p {
    color: #8b8b8b;
    margin-top: 1em;
    line-height: 1.5;
    // prevent single characters for sticking to the right in rare cases
    padding-right: 1em;
  }
  .backButton {
    margin-left: var(--fxl-spacing-l);
    margin-right: var(--fxl-spacing-l);
    margin-bottom: calc(var(--fxl-spacing-sm) + 2px);
  }
  .mintingInstructions {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 1em;
    padding-top: 1em;
  }
}
.blur {
  filter: blur(40px);
  pointer-events: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-color: var(--fxl-color-background1);
  padding: var(--fxl-spacing-xxl);
  max-width: 600px;
  border-radius: var(--fxl-spacing-sm);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  .qr {
    display: flex;
    justify-content: center;
    canvas {
      width: 60%;
    }
  }
  .qr_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px;
  }
  p {
    margin-top: var(--fxl-spacing-l);
    margin-bottom: var(--fxl-spacing-l);
    line-height: 1.5;
  }
  button {
    width: 100%;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
