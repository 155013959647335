.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  height: 100vh;
  gap: 15vw;
  padding: 0 10vw;
  overflow-y: hidden;
}

.project {
  font-size: 1.2em;
  cursor: pointer;

  .title {
    margin-top: 1em;
  }

  .image-wrapper {
    position: relative;
  }

  .preview {
    width: min(50vw, 50vh);
    max-height: 80vh;
    position: relative;
    z-index: 10;
  }

  @keyframes op {
    from {
      opacity: 0.15;
    }
    to {
      opacity: 0.35;
    }
  }

  .blur {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(80vh, 80vw);
    filter: blur(128px);
    z-index: 9;
    opacity: 0.2;
    animation: op 3s ease-in-out 0s infinite alternate-reverse;
    pointer-events: none;
  }

  span {
    opacity: 0.5;
    font-style: italic;
    font-size: 0.8em;
  }
}
