.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 100vh;
  /* padding: 15px; */
  gap: 40px;
}

.project_container {
  flex: 1 1 0;
  width: 100vw;
  height: 100vh;
  &.dual {
    display: flex;
    justify-content: space-around;
  }
}
