.container {
  --gutter: 2vw;
  --color-background: #1d2227;

  background-color: var(--color-background);
  height: 100vh;
  display: flex;
  flex-direction: row;
  font-size: max(1vw, 1vh);
}
.centered_loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.gentk_picker {
  padding: calc(2 * var(--gutter)) var(--gutter) 0 calc(2 * var(--gutter));
  width: 30vw;
}
.gallery {
  flex: 1;
}
.rarest {
  width: 30.88%;
}
