$slider-track-height-normal: 3px;
$slider-thumb-size-normal: 1vw / 2;
$slider-thumb-border-radius: 50%;

.root {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: 2em;

  .slider {
    width: 65%;
  }

  .text {
    font-size: 1.5em;
  }
}
