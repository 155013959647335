.root {
  display: flex;
  flex-direction: column;
  font-size: min(2vh, 2.5vw);
  overflow: hidden;
  height: 100%;
  &.dual {
    width: 50vw;
  }
}

.frame_wrapper {
  width: 100%;
  flex-grow: 2;
  position: relative;
}

.reveal_iframe_container_square {
  height: min(90vw, 80vh) !important;
  width: min(90vw, 80vh) !important;
  margin-left: auto;
  margin-right: auto;
}

.reveal_iframe_container_16_9 {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16 / 9;
}

.reveal_iframe_container_9_16 {
  width: auto !important;
  height: 100% !important;
  aspect-ratio: 9 / 16;
}

.reveal_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.reveal_infos {
  margin: 1.5em 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  &.minimal {
    align-items: flex-start;
    padding: 0 0.5em;
    font-size: 1rem;
    width: min(90vw, 80vh) !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.infos__user_badge {
  font-size: 1em;
  font-weight: normal !important;
  color: white !important;
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
