.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
}
.gallery {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: calc(2 * var(--gutter)) var(--gutter);
}
.gallery_elements {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

  .element {
    &.selected {
      img {
        outline: 4px solid var(--color-primary);
      }
    }
  }
}
.gallery_loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery_empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 0 10%;
  text-align: center;

  p {
    line-height: 1.5em;
    opacity: 0.7;
  }
}

.rarest {
  width: 25vw;
  padding: calc(2 * var(--gutter)) calc(2 * var(--gutter)) 0 var(--gutter);
  height: 100%;
}
