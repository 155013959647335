.root {
  margin-top: 24px;

  .infos {
    margin-top: 3px;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }
}
