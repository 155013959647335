.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-weight: bold;

  &.bottom {
    flex-direction: column-reverse;
  }
}
