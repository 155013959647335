.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 1em;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0.7em;
  cursor: pointer;
  color: inherit;
  opacity: 0.4;
  border: 2px solid var(--color-black);
  transition: 0.2s all ease-out;
  font-size: 0.8em;
  line-height: 0.8;
  text-align: center;

  & > * {
    color: var(--color-black);
    font-weight: normal;
  }

  &.selected {
    background: var(--color-black);
    opacity: 1;

    & > * {
      color: var(--color-background);
    }
  }
}

.placeholder {
  color: var(--color-gray);
  padding: 20px;
  font-size: var(--font-size-small);
}
