.root {
  width: var(--carousel-height);
  margin: 0 auto;
  margin-top: 1em;
  display: flex;
  flex-direction: column;

  .infos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .owned {
      display: flex;
      flex-direction: row;
      gap: 0.5em;
      color: #838893;

      * {
        color: inherit;
        font-weight: normal;
      }
    }
  }

  .qr {
    margin-top: 3em;
    width: 3.5em !important;
    height: 3.5em !important;
    align-self: flex-end;
  }

  .features_wrapper {
    flex-grow: 2;
    margin-top: 3em;
  }
}
