.root {
  font-size: 1.5em;
  padding: 2em;

  a {
    color: white;
    display: block;
    margin: 0.5em;
  }
}
