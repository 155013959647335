.input {
  background-color: var(--color-gray-vvlight);
  border: none;
  width: 10em;
  font-size: 2em;
  padding: 0.2em 0.4em;
  text-align: center;
  border: 1px solid var(--color-gray-light);
  border-radius: 4px;
}

.input:hover {
  border-color: var(--color-gray);
}

.input.error {
  border: 2px solid red;
}
